<template>
  <AModalContent class="god-ape-quest-finish-modal">
    <video @ended="onVideoEnd" autoplay>
      <source :src="videoUrl" type="video/mp4" />
    </video>
  </AModalContent>
</template>
<script setup>
import { computed, ref } from "vue-demi";
import AModalContent from "../Modal/AModalContent.vue";
import GodApeQuestTeamChoice from "../GodApeQuest/GodApeQuestTeamChoice.vue";
import { godApeQuestChooseTeam } from "@/functions/god-ape-quest/god-ape-quest-choose-team";
import { useRouter } from "vue-router";
const props = defineProps({
  phase: { type: Number, default: 1 },
  redirectOnEnd: { type: Boolean, default: true },
  team: String
});
const emit = defineEmits(["close"]);

const router = useRouter();
const onVideoEnd = () => {
  if (props.redirectOnEnd) {
    router.push({ name: "GodApeQuest" });
  }
  emit("close");
};
const videoUrl = computed(() => {
  if (props.phase === 6) {
    if (props.team === 'ava') {
      return require(`@/assets/video/god-ape-quest/phase-${props.phase}-ava-complete.mp4`)
    } else {
      return require(`@/assets/video/god-ape-quest/phase-${props.phase}-vyl-complete.mp4`)
    }
  }
  return require(`@/assets/video/god-ape-quest/phase-${props.phase}-complete.mp4`)
}
);
</script>
<style lang="scss">
.god-ape-quest-finish {
  &-modal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    video {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: auto;
    }
  }
}
</style>
